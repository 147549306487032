import { Locale } from '@core/Entities/Locale/Locale.entity';
import { AggregationsResults } from '@core/Entities/Search/Aggregations/Aggregations.entity';
import { UrlParams } from '@core/Entities/Search/QueryParams.entity';
import { Filter } from '@core/Entities/Search/SearchFilters/Filter.entity';
import { AGGREGATION_FILTERS } from '@core/Services/Search/Filters.constants';
import { mapQueryParams } from '@core/Services/Search/Utils/mapQueryParams';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { aggregationsToMakesModels } from '@core/Utils/Search/AggregationsToMakesModels';
import { getMarketSpecificUrl } from '@core/Utils/Search/GetMarketSpecificUrl';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';

export const fetchAggregationsWithActiveFilters = async (
  locale: Locale,
  activeFilters: Filter[],
  revalidate?: number,
  tenant?: Tenant,
) => {
  const filtersAsUrlParams = () => {
    const urlParams: UrlParams = {};
    activeFilters.forEach(fltr => {
      urlParams[fltr.id] = fltr.value;
    });
    return urlParams;
  };

  const URL = getMarketSpecificUrl(locale);

  const queryParams = activeFilters.length
    ? `&${mapQueryParams(filtersAsUrlParams()).queryString}`
    : '';

  const applyExclusions = tenant === 'rac' ? '&apply-exclusions=rac' : '';

  const aggsToFetch = queryParams.includes('model=')
    ? [...AGGREGATION_FILTERS, 'variant']
    : AGGREGATION_FILTERS;

  const data = await fetchJson(
    `${URL}/count?field=${aggsToFetch.join('&field=')}${queryParams}${applyExclusions}`,
    {
      headers: {
        'x-heycar-tenant': locale,
      },
      ...(revalidate !== undefined ? { next: { revalidate } } : {}),
    },
  );
  if (!data) {
    throw new Error();
  }

  const { aggregations, total: totalCount } = data as AggregationsResults;
  const { makes, models, variants } = aggregationsToMakesModels(
    aggregations.make!,
    aggregations.model!,
    aggregations.variant,
  );

  return {
    'stock-condition': aggregations['stock-condition'],
    price: aggregations.price,
    'monthly-price': aggregations['monthly-price'],
    makes,
    models,
    variants,
    'fuel-type': aggregations['fuel-type'],
    'body-type': aggregations['body-type'],
    color: aggregations.color,
    'gear-box': aggregations['gear-box'],
    doors: aggregations.doors,
    seats: aggregations.seats,
    totalCount,
    'finance-product': aggregations['finance-product'],
    'engine-size': aggregations['engine-size'],
  };
};
